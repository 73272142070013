<template>
  <IonApp :class="isLoading ? 'opacity-0' : ''">
    <ion-router-outlet></ion-router-outlet>
    <ModalOutlet />
  </IonApp>
</template>

<script setup lang="ts">
import { IonApp } from '@ionic/vue'
import setLocale from '@/helpers/setLocale'
import ModalOutlet from './components/ModalOutlet.vue'
import { useCustomBrandingQuery } from './queries/customBranding'
import { watch } from 'vue'
setLocale()
const { data, isLoading } = useCustomBrandingQuery()
watch(
  data,
  () => {
    if (!!data.value) {
      const styles = document.createElement('style')
      styles.innerHTML = data.value.css
      document.head.appendChild(styles)
    }
  },
  { immediate: true }
)
</script>
./queries/customBranding
