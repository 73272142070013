import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'

export function useCustomBrandingQuery() {
  return useQuery({
    queryKey: ['customBranding'],
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const site = window.location.hostname
      if (['localhost2', 'workspace.crewdentials.com'].includes(site)) {
        localStorage.removeItem('customBranding')
        return false
      }
      const savedBranding = JSON.parse(localStorage.getItem('customBranding') as string)

      const brandingRequest = axios
        .get(`${import.meta.env.VITE_API_BASE_URL}/customBranding/byUrl`)
        .then(({ data }) => {
          localStorage.setItem('customBranding', JSON.stringify(data))
          return data
        })
      if (!!savedBranding) {
        return savedBranding
      }
      return await brandingRequest
    }
  })
}
