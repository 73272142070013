import { useAuthStore } from '@/stores/auth'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import axios from 'axios'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw | any> = [
  {
    path: '/',
    beforeEnter: async () => {
      const { checkAuth } = useAuthStore()
      const loggedIn = await checkAuth()
      return loggedIn || { name: 'login' }
    },
    component: () => import('@/views/RootView.vue'),
    children: [
      {
        path: '',
        name: 'welcome',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/SettingsView.vue')
      },
      {
        path: '/access/:accessId',
        name: 'access',
        component: () => import('@/views/ExternalAccessView.vue')
      },
      {
        path: '/access/:accessId/profile/:crewProfileId',
        component: () => import('@/views/ExternalAccessCrewProfileView.vue')
      },
      {
        path: '/workspace/:workspaceId/docs/:docId',
        component: () => import('@/views/DocumentView.vue'),
        name: 'document'
      },
      {
        path: '/workspace/:workspaceId/reports',
        component: () => import('@/views/ReportsView.vue')
      },
      {
        path: '/workspace/:workspaceId/verifications/:verificationId',
        component: () => import('@/views/VerificationView.vue'),
        name: 'verification'
      },
      {
        path: '/workspace/:workspaceId/verify/:documentId',
        component: () => import('@/views/VerificationView.vue'),
        name: 'newVerification'
      },
      {
        path: '/workspace/:workspaceId/profiles/:authId',
        name: 'userProfiles',
        component: () => import('@/views/UserProfilesView.vue')
      },
      {
        path: '/workspace/:workspaceId/workflow-submission/:profileId',
        name: 'workflowSubmission',
        component: () => import('@/views/WorkflowSubmissionView.vue')
      },
      {
        path: '/workspace/:workspaceId/crew/:crewId',
        name: 'crewMember',
        component: () => import('@/views/CrewProfileView.vue')
      },
      {
        path: '/workspace/:workspaceId/templates',
        name: 'workflows',
        component: () => import('@/views/TemplatesView.vue')
      },
      {
        path: '/workspace/:workspaceId/templates/:templateId',
        name: 'workflow',
        component: () => import('@/views/TemplateView.vue')
      },
      {
        path: '/workspace/:workspaceId/settings',
        name: 'workspaceSettings',
        component: () => import('@/views/WorkspaceSettingsView.vue')
      },
      {
        path: '/workspace/:workspaceId/archive',
        name: 'archive',
        component: () => import('@/views/ArchiveView.vue')
      },
      {
        path: '/workspace/:workspaceId/',
        redirect: (to: RouteLocation) => `${to.path}/home`
      },
      {
        path: '/workspace/:workspaceId/groups/:groupId',
        name: 'group',
        component: () => import('@/views/GroupView.vue')
      },
      {
        path: '/workspace/:workspaceId/',
        component: () => import('@/views/TabsView.vue'),
        children: [
          {
            path: 'home',
            component: () => import('@/views/DashboardView.vue'),
            name: 'home'
          },
          {
            path: 'docs',
            component: () => import('@/views/DocumentsView.vue')
          },
          {
            path: 'crew',
            name: 'crew',
            component: () => import('@/views/CrewView.vue')
          },
          {
            path: 'groups',
            component: () => import('@/views/GroupsView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: async () => {
      const { checkAuth } = useAuthStore()
      const loggedIn = await checkAuth()
      return !loggedIn || { path: '' }
    },
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/verify/:token',
    name: 'issuerVerify',
    component: () => import('@/views/VerifyView.vue')
  },
  {
    path: '/view/:accessId',
    name: 'groupExternalView',
    component: () => import('@/views/ExternalAccessView.vue')
  },
  {
    path: '/view/:accessId/profile/:crewProfileId',
    component: () => import('@/views/ExternalAccessCrewProfileView.vue')
  },
  {
    path: '/profile/:userId',
    name: 'publicProfile',
    component: () => import('@/views/PublicProfileView.vue')
  }
]

const router = createRouter({
  // Use: createWebHistory(process.env.BASE_URL) in your app
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if (!!to.params.workspaceId) {
    axios.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL}/w/${to.params.workspaceId}`
  } else {
    axios.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL}`
  }
})

export default router
